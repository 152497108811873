<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-overlay :value="true" :opacity="0.99" :z-index="10" color="white" class="text-center full">
      <v-img
        contain
        max-width="120"
        :src="require('@/assets/Todo-carta-logo.png')"
      ></v-img>
      <v-progress-linear
          color="blue-grey lighten-3"
          indeterminate
          rounded
          height="4"
        ></v-progress-linear>
      <!-- <h5 class="blue-grey--text mt-2">Cerrando Sesión</h5> -->
    </v-overlay>
  </v-container>
</template>

<script>

  export default {
    name: 'DashboardDashboard',
    data () {
      return {
        dishe:{
          image:''
        },
        snackbar:{
          text:'',
          active: false,
          type:''
        },
        description:'',
        side: true,
        editorOption: {
          placeholder: "Descripcion",
          modules: {
            toolbar: [
              ['bold', 'italic', 'underline', 'strike'],
              ['blockquote', 'code-block'],
              [{ 'header': 1 }, { 'header': 2 }],
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              [{ 'script': 'sub' }, { 'script': 'super' }],
              [{ 'indent': '-1' }, { 'indent': '+1' }],
              [{ 'direction': 'rtl' }],
              [{ 'size': ['small', false, 'large', 'huge'] }],
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
              [{ 'font': [] }],
              [{ 'color': [] }, { 'background': [] }],
              [{ 'align': [] }],
              ['clean'],
              ['link']
            ],
          }
        },
      }
    },
    mounted(){
      if(this.$store.getters['auth/isAdmin']){
        this.$router.push("/tc-admin/restaurantes");
      }
      else{
        this.$router.push("/tc-admin/principal");
      }
    },
    methods: {
      complete (index) {
        this.list[index] = !this.list[index]
      },
      async getSales(){
        this.load_sale= true;
        this.loaders= true;
        this.sales = [];
        try{
          const { data } = await this.$api.get('api/dashboard');

          // const objectArray = Object.entries(data.data.sales);
          // objectArray.forEach(([key, value]) => {
          //     this.b = key;
          //     this.sales.push(value);
          // });

          this.sales = data.data.sales;

          this.load_sale= false;
          this.loaders = false;
        }
        catch(error){
          this.load_sale= false;
          this.snackbar = {
            text: 'Lo sentimos, hubo un error al intentar obtener las Ventas',
            type: 'error',
            active:true,
          };

        }
      },
      renderImage(file){
        const image = file.target.files[0] || file.dataTransfer.files
        if(image.length === 0) return;

        this.dishe.image = image;

        const preview = document.querySelector('#imagePreviewDishe');

        let reader = new FileReader();
        reader.readAsDataURL(image);

        reader.onload = () => {
          if (image.type.match('image/png') || image.type.match('image/jpeg') || image.type.match('image/gif')) {
            preview.style.backgroundImage = `url("${ reader.result }")`;
          }
        };
      },
      deleteImage(){
        this.dishe.image = '';
      }
    },
    computed:{
      date(){
        const f = new Date();
        return f.toDateString();
      }
    }
  }
</script>
<style scope>
  .v-data-table__expand-icon{
    color: #00cae3 !important;
    font-size: 30px !important;
  }

  .sidebar_right{
    background: #fbfcfd !important;
  }

  .imagezone{
    z-index: 999;
    cursor: pointer;
    transition: all .15s ease;
    text-align: center;
    color: #283145;
    border: 2px dashed #2c364c;
    border-radius: .375rem;
    background-color: #fff;
  }

  .imagezone:hover{
    color:#525f7f;border-color:#8898aa;background-color: #fff;
  }
</style>
